<template>
<section class="py_20">
  <div class="d-flex align-items-center justify-content-center h-100 py_40">
    <div class="page_not_found text-center h-100 w-50">
        <h1 class="text-danger">403</h1>
        <h2 class="m-4 text-uppercase"> Access Forbidden! </h2>
                    <p> You dont have permission to access this area </p>
        <div class="separator  transparent center  " style="margin-top:35px;"></div>
          <p>
            <button @click="redirection()" class="btn btn_primary m-4">Back to homepage</button>                
          </p>
      </div>
  </div>
  </section>
</template>

<script>
import $ from "jquery";
export default {
  name: "403",
   methods: {
    redirection() {        
      setTimeout(() => {
        $('.loading-page').css('display','none');
      }, 500);        
      this.$router.push({ name: "home" }).catch(() => true);
    }
  },
  mounted(){
    let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    // if(company_data.company_code.toLowerCase()=='airbnb')
    console.log('in 403 comp info is :',company_data);
  }
};
</script>
